import Container from 'react-bootstrap/Container';
import CameraView from './camera/camera-view';
import { Device } from '../types/device';

export interface DevicesViewProps {
  devices: Array<Device>;
}

export default function DevicesView({ devices }: DevicesViewProps) {
  return (
    <>
      <Container>
        <div className="row">
          <h3>Live View</h3>
          {devices.map((device) => (
            <div className="d-flex align-content-start flex-wrap">{device.cameras?.map((camera) => <CameraView key={camera.id} camera={camera} />)}</div>
          ))}
        </div>
      </Container>
    </>
  );
}
