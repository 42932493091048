import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { useValidPassword } from '../../hooks/auth-hooks';
import { useContext, useState } from 'react';
import { SessionContext } from '../../context/session-context';
import { useNavigate } from 'react-router-dom';

export default function ChangePassword() {
  const { password: oldPassword, setPassword: setOldPassword, passwordIsValid: oldPasswordIsValid } = useValidPassword('');
  const { password: newPassword, setPassword: setNewPassword, passwordIsValid: newPasswordIsValid } = useValidPassword('');
  const [error, setError] = useState('');
  const [reset, setReset] = useState(false);
  const history = useNavigate();

  const sessionContext = useContext(SessionContext);

  const handleOldPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setOldPassword(value);
  };
  const handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setNewPassword(value);
  };
  const changePassword = async () => {
    try {
      await sessionContext.changePassword(oldPassword, newPassword);
      setReset(true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      console.error('Error occurred', error);
      setError(err.message);
    }
  };
  const logout = async () => {
    try {
      await sessionContext.logout();
      history('/');
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      }
    }
  };
  const resetPassword = (
    <>
      <Form.Group className="mb-4" controlId="emailGroup">
        <Form.Label>Old Password</Form.Label>
        <Form.Control type="password" value={oldPassword} onChange={handleOldPasswordChange} />
      </Form.Group>
      <Form.Group className="mb-4" controlId="passwordGroup">
        <Form.Label>New Password</Form.Label>
        <Form.Control type="password" value={newPassword} onChange={handleNewPasswordChange} />
      </Form.Group>
      <Button variant="primary" type="button" onClick={changePassword}>
        Change Password
      </Button>
      <div className="alert alert-danger" role="alert" hidden={error === ''}>
        {error}
      </div>
    </>
  );
  const passwordReset = (
    <>
      <p>Password changed successfully.</p>
      <Button variant="primary" type="button" onClick={logout}>
        Sign out
      </Button>
    </>
  );
  return (
    <>
      <Container as="main" className="py-4 px-3 mx-auto">
        <Header />
        <Container style={{ width: '350px' }}>
          <Form>{!reset ? resetPassword : passwordReset}</Form>
        </Container>
        <Footer />
      </Container>
    </>
  );
}
