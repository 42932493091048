import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import { Camera } from '../../types/camera';

export interface CameraProps {
  camera: Camera;
}

export default function CameraView({ camera }: CameraProps) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function isOnline(): boolean {
    const now = new Date();
    const timestamp = now.getTime() / 1000;
    return timestamp - camera.captured > 300;
  }

  return (
    <>
      <Card id={camera.id} style={{ maxWidth: '225px', marginRight: '5px', marginBottom: '5px' }}>
        <Card.Img variant="top" src={camera.url}></Card.Img>
        <Card.Body>
          <Card.Title>{camera.deviceName}</Card.Title>
          <div>{camera.name}</div>
          <div style={{ display: 'inline-block', fontWeight: 'bold' }}>Status:</div>
          <div className="online" hidden={isOnline()}></div>
          <div className="offline" hidden={!isOnline()}></div>
          <Card.Text>
            <b>Captured:</b> {new Date(camera.captured * 1000).toLocaleString()}
          </Card.Text>
          <Button variant="primary" className="rounded-pill" style={{ width: '140px' }} onClick={handleShow}>
            full screen
          </Button>
        </Card.Body>
      </Card>
      <Modal id={`${camera.id}Modal`} show={show} onHide={handleClose} fullscreen={true}>
        <Modal.Header closeButton>
          <Modal.Title>
            {camera.deviceName}: {camera.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <img src={camera.url} alt={camera.name} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
